import React, { useEffect, useState } from 'react';
import { EditorAppSDK } from '@contentful/app-sdk';
import { useSDK } from '@contentful/react-apps-toolkit';
import { Grid, Box, Heading } from '@contentful/f36-components';
import { ContentfulRepository } from '../data/contentfulRepository';
import UnderDevelopmentWarning from '../components/DevelopmentWarning';
import Preview from '../components/Preview';
import ProductsSelector from '../components/ProductsSelector';
import {
  CONTENTFUL_FIELD_SCOPE_DEFINITION,
  EMPTY_APPLICATION_STATE,
  EMPTY_SCOPE_DEFINITION as INITIAL_SCOPE_DEFINITION,
} from '../constants';
import { CategoriesSelector } from '../components/CategoriesSelector';
import { AttributesSelector } from '../components/AttributesSelector';
import { useCimpressAuthToken } from '@vp/contentful-app/auth';
import { MsxProduct } from '../clients/MsxService';
import useAttributes from '../hooks/useAttributes';
import useProducts from '../hooks/useProducts';
import useCategories from '../hooks/useCategories';
import useAlgoliaFilter from '../hooks/useAlgoliaFilter';
import { mapToScopeDefinition } from '../mappers/applicationState';
import { mapToApplicationState } from '../mappers/scopeDefinition';
import { ApplicationState } from '../model/ApplicationState';
import { Category } from '../model/Category';
import { ScopeDefinition } from '../model/ScopeDefinition';

const Entry = () => {
  const sdk = useSDK<EditorAppSDK>();
  const contentfulRepository = new ContentfulRepository(sdk);
  const authToken = useCimpressAuthToken();

  const [appState, setAppState] = useState<ApplicationState>(EMPTY_APPLICATION_STATE);
  const [scopeDefinition, setScopeDefinition] = useState<ScopeDefinition>(INITIAL_SCOPE_DEFINITION);
  const locale = contentfulRepository.getDefaultLocaleForSpace();

  const { mappedAttributes, isLoadingAttributes } = useAttributes();
  const { products, isLoadingProducts } = useProducts(locale);
  const { categories, isLoadingCategories } = useCategories(locale);
  const { algoliaFilter, errorGeneratingAlgoliaFilter } = useAlgoliaFilter(authToken, scopeDefinition, locale);

  useEffect(() => {
    const storedScopeDefinition =
      contentfulRepository.getEntryFieldValue<ScopeDefinition>(CONTENTFUL_FIELD_SCOPE_DEFINITION) ??
      INITIAL_SCOPE_DEFINITION;

    const applicationState = mapToApplicationState(storedScopeDefinition);
    setAppState(applicationState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const scopeDefinition = mapToScopeDefinition(appState);
    setScopeDefinition(scopeDefinition);
  }, [appState]);

  useEffect(() => {
    saveEntryData(scopeDefinition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeDefinition]);

  const handleSelectProducts = (product: MsxProduct) => {
    setAppState({
      ...appState,
      products: [...appState.products, product],
    });
  };

  const handleRemoveProducts = (product: MsxProduct) => {
    setAppState({
      ...appState,
      products: appState.products.filter((item) => item.mpvId !== product.mpvId),
    });
  };

  const handleSelectAttribute = (attributeName: string) => {
    setAppState({
      ...appState,
      attributes: [...appState.attributes, { name: attributeName, values: [] }],
    });
  };

  const handleRemoveAttribute = (attributeName: string) => {
    setAppState({
      ...appState,
      attributes: appState.attributes.filter((item) => item.name !== attributeName),
    });
  };

  const handleSelectAttributeValue = (attributeName: string, attributeValue: string) => {
    const attribute = appState.attributes.find((attribute) => attribute.name === attributeName);
    if (attribute) {
      const newAttribute = { ...attribute, values: [...(attribute?.values ?? []), attributeValue] };
      const newAttributes = appState.attributes.map((att) => {
        if (attributeName === att.name) {
          return newAttribute;
        } else {
          return att;
        }
      });
      setAppState({
        ...appState,
        attributes: newAttributes,
      });
    }
  };

  const handleRemoveAttributeValue = (attributeName: string, attributeValue: string) => {
    const attribute = appState.attributes.find((attribute) => attribute.name === attributeName);
    if (attribute) {
      const newValuesArray = attribute.values.filter((item) => item !== attributeValue);
      const newAttribute = { ...attribute, values: newValuesArray };
      const newAttributes = appState.attributes.map((att) => {
        if (attributeName === att.name) {
          return newAttribute;
        } else {
          return att;
        }
      });
      setAppState({
        ...appState,
        attributes: newAttributes,
      });
    }
  };

  const handleSelectCategory = (category: Category) => {
    setAppState({
      ...appState,
      categories: [...appState.categories, category],
    });
  };

  const handleRemoveCategory = (category: Category) => {
    setAppState({
      ...appState,
      categories: appState.categories.filter((item) => item.id !== category.id),
    });
  };

  const saveEntryData = (scope: ScopeDefinition) => {
    if (scope !== INITIAL_SCOPE_DEFINITION) {
      contentfulRepository.setEntryFieldValue(CONTENTFUL_FIELD_SCOPE_DEFINITION, scope);
    }
  };

  return (
    <Grid
      columns="1fr 1fr"
      rows="1fr 2fr 2fr"
      rowGap="spacingM"
      columnGap="spacingM"
      style={{ height: '100%', gridTemplateRows: 'auto' }}
    >
      <Grid.Item area="span 1 / span 2">
        <UnderDevelopmentWarning />
      </Grid.Item>
      <Grid.Item>
        <Box padding="spacingL">
          <Heading>Categories</Heading>
          <CategoriesSelector
            locale={locale}
            onSelectCategory={handleSelectCategory}
            onRemoveCategory={handleRemoveCategory}
            selectedCategories={appState.categories}
            categories={categories}
            isLoading={isLoadingCategories}
          />
        </Box>
        <Box padding="spacingL">
          <Heading>Product selector</Heading>
          <ProductsSelector
            locale={locale}
            onSelectProduct={handleSelectProducts}
            onRemoveProduct={handleRemoveProducts}
            selectedProducts={appState.products}
            products={products}
            isLoading={isLoadingProducts}
          />
        </Box>
        <Box padding="spacingL">
          <Heading>Attributes selector</Heading>
          <AttributesSelector
            onSelectAttribute={handleSelectAttribute}
            onRemoveAttribute={handleRemoveAttribute}
            onSelectAttributeValue={handleSelectAttributeValue}
            onRemoveAttributeValue={handleRemoveAttributeValue}
            selectedAttributes={appState.attributes}
            attributes={mappedAttributes}
            isLoading={isLoadingAttributes}
            locale={locale}
          />
        </Box>
        <Box padding="spacingL">
          <Heading>Debug</Heading>
          <div>
            <pre style={{ textWrap: 'pretty' }}>{JSON.stringify(scopeDefinition, null, 2)}</pre>
          </div>
        </Box>
        <Box padding="spacingL">
          <Heading>Algolia Filter</Heading>
          <div>
            <pre style={{ textWrap: 'wrap' }}>{JSON.stringify(algoliaFilter, null, 2)}</pre>
          </div>
        </Box>
      </Grid.Item>
      <Grid.Item>
        <Box padding="spacingL">
          <Preview previewFiltersUrl={algoliaFilter} defaultLocale={locale} hasError={errorGeneratingAlgoliaFilter} />
        </Box>
      </Grid.Item>
    </Grid>
  );
};

export default Entry;
