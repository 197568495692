import { Attribute } from '../clients/DiscoveryPagesService';
import { MsxProduct } from '../clients/MsxService';
import { ApplicationState } from '../model/ApplicationState';
import { Category } from '../model/Category';
import { ScopeDefinition, AttributeFilter, ProductFilter, CategoryFilter } from '../model/ScopeDefinition';

export const mapToApplicationState = (scopeDefinition: ScopeDefinition): ApplicationState => {
  const defaultScope = scopeDefinition.default;

  const applicationState = {
    categories: defaultScope.categories.filter((c) => c.level || !isNaN(Number(c.value))).map(mapCategory),
    products: defaultScope.products.map(mapProduct),
    attributes: defaultScope.attributes.map(mapAttributeFilter),
  };

  return applicationState;
};

const mapAttributeFilter = (attributeFilter: AttributeFilter): Attribute => {
  return {
    name: attributeFilter.key,
    values: attributeFilter.selectedValues,
  };
};

const mapCategory = (category: CategoryFilter): Category => {
  return {
    id: category.key,
    level: category?.level ?? Number(category.value),
  };
};

const mapProduct = (product: ProductFilter): MsxProduct => {
  return {
    mpvId: product.value,
    productKey: '',
  };
};
