import { MsxProduct } from '@clients/MsxService';
import { ApplicationState } from './model/ApplicationState';
import { ScopeDefinition } from './model/ScopeDefinition';
import { Attribute } from '@clients/DiscoveryPagesService';

export const CONTENTFUL_FIELD_SCOPE_DEFINITION = 'scopeDefinition';
export const EMPTY_SCOPE_DEFINITION: ScopeDefinition = { default: { categories: [], products: [], attributes: [] } };
export const EMPTY_APPLICATION_STATE: ApplicationState = { categories: [], products: [], attributes: [] };
export const EMPTY_PRODUCT: MsxProduct = { mpvId: '', productKey: '' };
export const EMPTY_ATTRIBUTE: Attribute = { name: '', values: [] };
